"use client";

import Close from "@/components/icons/Close";
import Button from "@/components/ui/Button";
import { Input } from "@/components/ui/Input";
import {
  ProductCardList,
  ProductCardListSkeleton
} from "@/features/product/components/ProductCardList";
import { ProductCard } from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import { Slot } from "@radix-ui/react-slot";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import React, {
  ComponentProps,
  Fragment,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
  useTransition
} from "react";
import { create } from "zustand";
import {
  getSearchUrl,
  useProductSearch,
  useSearchUrl
} from "../hooks/useProductSearch";
import styles from "./index.module.css";

interface SearchModalState {
  open: boolean;
  openPath: string | null;

  setOpen: (isOpen: boolean) => void;
}

export const useSearchModal = create<SearchModalState>((set) => ({
  open: false,
  openPath: null,
  setOpen: (open) =>
    set({ open, openPath: open ? window.location.pathname : null })
}));

interface SearchModalProps {
  title?: string;
  featuredProducts?: ProductCard[];
}

export const SearchModal = ({
  title,
  featuredProducts: products = []
}: SearchModalProps) => {
  const open = useSearchModal((state) => state.open);
  const setOpen = useSearchModal((state) => state.setOpen);
  const ref = useRef<HTMLDivElement>(null!);
  const inputRef = useRef<HTMLInputElement>(null!);
  const router = useRouter();
  const [query, setQuery] = useState("");
  const [isPending, startTransition] = useTransition();
  const pathname = usePathname();
  const t = useTranslations();

  // Open close state
  useEffect(() => {
    const aside = ref.current;
    if (open) {
      disableBodyScroll(aside);
      aside.scrollTo({ top: 0 });
      // Timeout is needed for browser to focus
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    } else {
      enableBodyScroll(aside);
      // After closing wipe query
      setQuery("");
    }

    return () => {
      enableBodyScroll(aside);
    };
  }, [open]);

  /**
   * Close the modal if the user navigates away
   */
  useEffect(() => {
    const state = useSearchModal.getState();
    if (state.openPath !== pathname && state.open) {
      setOpen(false);
    }
    // React only to pathname change
  }, [pathname, setOpen]);

  /**
   * Close the modal on submit and navigate to the search page
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (query) {
      startTransition(() => {
        router.push(getSearchUrl(query));
      });
    }
  };
  const pendingRef = useRef(isPending);
  useEffect(() => {
    if (pendingRef.current) {
      setOpen(false);
    }

    pendingRef.current = isPending;
  }, [isPending, setOpen]);

  return (
    //@ts-expect-error Inert is not available
    <aside
      ref={ref}
      className={styles.searchModal}
      data-state={open ? "open" : "closed"}
      // Accessibility
      role="dialog"
      {...{ inert: open ? undefined : "" }} // Invalid @types/react
    >
      <div className={styles.content}>
        <SearchClose />
        <section className={clsx(styles.searchField)}>
          <form onSubmit={handleSubmit}>
            <Input
              ref={inputRef}
              className={styles.input}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onClear={() => setQuery("")}
              variant="search"
              type="search"
              name="search"
              placeholder={t("search.title")}
            />
          </form>
        </section>
        <ProductSearchList
          query={query}
          title={title}
          featuredProducts={products}
        />
      </div>
    </aside>
  );
};

export const MODAL_MAX_COUNT = 4;

const ProductSearchList = ({
  featuredProducts = [],
  title,
  query
}: SearchModalProps & { query: string }) => {
  const searchTerm = useDeferredValue(query);

  const showSearch = !!searchTerm;
  return (
    <section
      key={showSearch ? "search" : "featured"}
      className={styles.productSection}
    >
      <div
        className={clsx(!showSearch && styles.hidden)}
        key="product-search-results"
      >
        <ProductSearchResults query={searchTerm} />
        <DoNotRemove />
      </div>

      <section
        className={clsx(showSearch && styles.hidden)}
        key="featured-products"
      >
        <h2 className={styles.title}>{title}</h2>
        <ProductCardList
          key="sdsdsdsds"
          data-layout="none"
          cards={featuredProducts}
        />
        <DoNotRemove />
      </section>
    </section>
  );
};

const ProductSearchResults = ({ query }: { query: string }) => {
  const { data, isFetching, isLoading } = useProductSearch(query, {
    enabled: !!query,
    productsPerPage: MODAL_MAX_COUNT
  });

  const url = useSearchUrl(query);
  const t = useTranslations();

  if (isLoading)
    return (
      <Fragment key="product-skeleton">
        <h2 className={styles.searchTitle}>{t("search.results")}</h2>
        <ProductCardListSkeleton data-layout="none" />
      </Fragment>
    );

  if (!isFetching && !data?.products?.length && query) {
    return (
      <p className={styles.noResults}>
        No matches found. Try to rephrase or{" "}
        <Link href="/products">browse all products.</Link>
      </p>
    );
  }

  return (
    <Fragment key="products-search-results">
      <h2 className={clsx(styles.searchTitle)}>{t("search.results")}</h2>
      <ProductCardList data-layout="none" cards={data?.products || []} />
      {data && (data.productCount ?? 0) > MODAL_MAX_COUNT && (
        <Button mode="link" className={styles.button} href={url}>
          {t("search.seeAllResults")}
        </Button>
      )}
    </Fragment>
  );
};

export const SearchModalTrigger = ({
  asChild,
  children,
  ...props
}: ComponentProps<"button"> & { asChild?: boolean }) => {
  const setOpen = useSearchModal((state) => state.setOpen);
  const Component = asChild ? Slot : "button";
  const pathname = usePathname();

  const handleOpen = () => {
    // Noop if we are on the search page
    if (pathname.includes("/search")) return;
    setOpen(true);
  };

  return (
    <Component {...props} onClick={handleOpen}>
      {children}
    </Component>
  );
};

export const SearchClose = ({
  className,
  ...props
}: ComponentProps<"button">) => {
  const setOpen = useSearchModal((state) => state.setOpen);
  return (
    <button
      className={styles.searchClose}
      {...props}
      onClick={() => setOpen(false)}
    >
      <Close />
    </button>
  );
};

/**
 * Chrome is being ridiculous and keeps the previous render while painting the new one.
 * Adding this element to the DOM to force the browser to paint the new render.
 */
const DoNotRemove = () => (
  <p
    style={{
      color: "var(--neutral-2)",
      userSelect: "none",
      pointerEvents: "none"
    }}
    aria-hidden="true"
  >
    .
  </p>
);
